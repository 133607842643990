import InfoBar from 'components/common/InfoBar'
import Tabs from 'components/common/Tabs'
import { useAppSelector } from 'state/hooks'
import {
  BORROW,
  COLLATERAL,
  DashTab,
  hideBox,
  selectShowInfoBox,
  setDashInput
} from 'state/slices/ui/dash'
import SearchBar from 'components/common/SearchBar'
import CollateralList from 'components/dashboard/CollateralList'
import Column from 'components/common/Column'
import BigBal from 'components/common/BigBal'
import BalancesList from 'components/dashboard/BalancesList'
import {
  detrmineHealthColor,
  dollarsCompact,
  formatCurrency,
  formatHealthFactor,
  formatPercentage,
  formatPercentageCompact
} from 'toolbox/format'
import BalanceChart from 'components/dashboard/BalanceChart'
import { selectAppLoaded } from 'state/slices/app/appSelect'
import { selectDashUi } from 'state/slices/ui/uiSelect'
import { DataPointProps } from 'components/common/DataPoint'
import { isEmpty } from 'ramda'
import SegmentedProgressBar from 'components/charts/SegmentedProgressBar'
import {
  selectBrokersErrored,
  selectBrokersWithMeta,
  selectPricesFromBrokers
} from 'state/slices/app/brokers'

import {
  isRedZone,
  isYellowZone,
  selectEquity,
  selectFormattedPositions,
  selectFreeEquity,
  selectHealthFactor,
  selectMinimumRequiredEquity,
  selectPortfolioBusy,
  selectPortfolioErrored,
  selectPortfolioLoaded,
  selectTotalCollateral,
  selectTotalLiability
} from 'state/slices/user/portfolio'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import {
  getWalletBalanceByName,
  selectLoadedWalletBalances
} from 'state/slices/user/walletBalances'
import { Splash } from 'layout/Splash'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import MobileList from 'components/dashboard/MobileList'
import { RewardBanner } from 'components/rewards/RewardBanner'
import { USDTRedemptionBanner } from 'components/dashboard/USDTRedemptionBanner'
import { selectIncentivesToggleOn } from 'state/slices/app/toggles'

function Dashboard() {
  const { tabs, dashInput, selectedTab } = useAppSelector(selectDashUi)
  const inputLower = dashInput.toLowerCase()
  const brokers = useAppSelector(selectBrokersWithMeta)
  const prices = useAppSelector(selectPricesFromBrokers)
  const isLoadedApp = useAppSelector(selectAppLoaded)
  const positions = useAppSelector(selectFormattedPositions)
  const isLoadedUser = useAppSelector(selectPortfolioLoaded)
  const isBusyUser = useAppSelector(selectPortfolioBusy)
  const balances = useAppSelector(selectLoadedWalletBalances)
  const aptBal = getWalletBalanceByName(balances, 'aptos')
  const { connected } = useWallet()
  const filteredBrokers = brokers?.filter((b) => {
    const name = b.tokenMeta.name.toLowerCase()
    const chain = b.chainMeta.name.toLowerCase()
    const ticker = b.tokenMeta.ticker.toLowerCase()
    return name.includes(inputLower) || chain.includes(inputLower) || ticker.includes(inputLower)
  })
  const totalCollatVal = useAppSelector(selectTotalCollateral)
  const minEquityVal = useAppSelector(selectMinimumRequiredEquity)
  const liabilityVal = useAppSelector(selectTotalLiability)
  const freeEquityVal = useAppSelector(selectFreeEquity)
  const equityVal = useAppSelector(selectEquity)
  const healthFactor = useAppSelector(selectHealthFactor)
  const showInfoBox = useAppSelector(selectShowInfoBox)
  const showBox = showInfoBox && selectedTab === COLLATERAL
  const healthYellow = isYellowZone(healthFactor)
  const healthRed = isRedZone(healthFactor)
  const healthGreen = !healthYellow && !healthRed
  const isBrokersErrored = useAppSelector(selectBrokersErrored)
  const isPortfolioErrored = useAppSelector(selectPortfolioErrored)
  const incentivesToggleOn = useAppSelector(selectIncentivesToggleOn)

  let riskColor = 'green'

  const loadingOrErrorBroker = isBrokersErrored || isPortfolioErrored

  if (loadingOrErrorBroker) {
    riskColor = 'green'
  } else {
    if (isLoadedUser && healthYellow) {
      riskColor = 'orange'
    }
    if (isLoadedUser && healthRed) {
      riskColor = 'red'
    }
  }
  const displayBal = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return totalCollatVal
      case BORROW:
        return liabilityVal
    }
  }

  const positionData = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return positions.collaterals
      case BORROW:
        return positions.liabilities
    }
  }

  const dBal = displayBal(selectedTab) || 0
  const pData = positionData(selectedTab)
  const emptyPos = isEmpty(pData)

  const userDataLoading = isBusyUser && !isLoadedUser
  const userTotalSum = formatCurrency(dBal)

  const equityPercent = equityVal / totalCollatVal
  const liabilityPercent = liabilityVal / totalCollatVal

  const equityDataPoint = {
    label: 'Equity',
    tooltip: `Total net worth in USD. Collateral minus debt`,
    value: userDataLoading ? '--' : dollarsCompact(isLoadedUser, equityVal) || 0,
    colorBox: riskColor,
    secondaryValue:
      !userDataLoading && equityPercent ? formatPercentageCompact(equityPercent) : null
  }

  const debtDataPoint = {
    label: 'Debt',
    tooltip: `Total USD value of debt accumulated by borrowing`,
    value: userDataLoading ? '--' : dollarsCompact(isLoadedUser, liabilityVal) || 0,
    colorBox: 'blue',
    secondaryValue:
      !userDataLoading && liabilityPercent ? formatPercentageCompact(liabilityPercent) : null
  }

  // const netAPRDataPoint = {
  //   label: 'Net APR',
  //   tooltip: `Net APR of the portfolio including rewards`,
  //   value: userDataLoading ? '--' : formatPercentage(netAPR)
  // }

  const healthFactorDataPoint = {
    label: 'Health factor',
    tooltip: `Ratio of equity / required equity.  A value below 1 means the portfolio is at risk of liquidation`,
    value: userDataLoading ? '--' : formatHealthFactor(healthFactor),
    right: true,
    currentColor: !userDataLoading ? detrmineHealthColor(healthGreen, healthRed, healthYellow) : ''
  }

  const dashPrimaryData: DataPointProps[] = [equityDataPoint, debtDataPoint]

  const dashSecondaryData: DataPointProps[] = [healthFactorDataPoint]

  const segmentedProgressBarProps = {
    minEquityVal,
    totalCollatVal,
    liabilityVal,
    freeEquityVal,
    isLoadedUser,
    healthFactor
  }

  const collateralListProps = {
    brokers: filteredBrokers,
    isLoadedApp,
    tab: selectedTab,
    isLoadedUser,
    positions,
    balances
  }

  if (!connected) {
    return (
      <div className="dashboard">
        <Splash />
      </div>
    )
  }

  const hasNoAPT = aptBal === 0 || !aptBal

  const infoText = hasNoAPT
    ? 'Your Aptos wallet is empty. To engage with Superposition, please buy or transfer APT into your wallet.'
    : 'Deposited assets automatically earn yield through lending.'

  const infoBox: InfoBoxProps = {
    color: 'blue',
    showBox: showBox || hasNoAPT,
    text: infoText,
    fullWidth: true,
    hideBoxCallback: hideBox
  }

  return (
    <div className="dashboard">
      <InfoBar dataSet={dashPrimaryData} secondarySet={dashSecondaryData}>
        <SegmentedProgressBar {...segmentedProgressBarProps} />
      </InfoBar>

      <div className="inner-dash">
        <div className="container d-left">
          <div className="tab-bar">
            <Tabs tabs={tabs} variant="basic" />
            <BigBal bal={userTotalSum} disable={!isLoadedUser} label={'USD'} />
          </div>
          <div className="d-right mobile-only">
            <Column title={selectedTab} leftTitle>
              <BalanceChart positions={pData} prices={prices} brokers={brokers}>
                <BigBal
                  bal={userTotalSum}
                  disable={!isLoadedUser || emptyPos}
                  label={'Balance, USD'}
                  circle
                />
              </BalanceChart>
              <BalancesList
                isLoadedUser={isLoadedUser}
                prices={prices}
                brokers={brokers}
                tab={selectedTab}
              />
            </Column>
          </div>
          <USDTRedemptionBanner />
          {incentivesToggleOn && <RewardBanner />}
          <InfoBox {...infoBox} />
          <SearchBar value={dashInput} update={(s) => setDashInput(s)} />
          <div className="desk-only">
            <CollateralList {...collateralListProps} />
          </div>
          <div className="mobile-only">
            <MobileList {...collateralListProps} />
          </div>
        </div>

        <div className="d-right desk-only">
          <Column title={selectedTab} leftTitle>
            <BalanceChart positions={pData} prices={prices} brokers={brokers}>
              <BigBal
                bal={userTotalSum}
                disable={!isLoadedUser || emptyPos}
                label={'Balance, USD'}
                circle
              />
            </BalanceChart>
            <BalancesList
              isLoadedUser={isLoadedUser}
              prices={prices}
              brokers={brokers}
              tab={selectedTab}
            />
          </Column>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
